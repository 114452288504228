import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Filter} from './components/Deposit-Filter'
import {useDepositStore} from './components/Deposit-Store'
import {KTSVG} from '../../../../_metronic/helpers'
import Deposit_Export_CSV from '../components/Deposit-Export-CSV'
import ResubmitCallback from '../components/Deposit-Resubmit-Callback-Modal'
import DepositManagement_BulkApproval from '../components/Deposit-Bulk-Approval-Modal'
import {MatchSMSModal_Nagad} from '../components/Deposit-Match-SMS-NAGAD-Modal'
import {MatchSMSModal_Bkash} from '../components/Deposit-Match-SMS-BKASH-Modal'
import {ManualMatching} from '../components/Deposit-Manual-Matching-Modal'
import scrollToTop from '../../components/ScrollToTop'
import {TableTemplateCSS} from '../../components/Table/TableCSS'
import TableTemplate from '../../components/Table/TableTemplate'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import FormatNumber from '../../components/FormatNumber'
import FormatDate from '../../components/FormatDate'
import IndeterminateCheckbox from '../../components/Table/IndeterminateCheckbox'
import DepositRejectRequestModal from '../components/Deposit-Reject-Rquest-Modal'
import Deposit_Bulk_Reject_Modal from '../components/Deposit-Bulk-Reject-Modal'

const DepositManagementPage: React.FC = () => {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Table Related
  const [rowSelection, setRowSelection] = React.useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select'],
    right: ['action'],
  })
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => row)

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])
  const [firstLoad, setFirstLoad] = useState(true)
  //Table Related

  //States
  const [formData, setFormData] = useState([])
  const [depositData, setDepositData] = useState(Object)
  const [showMetrics, setShowMetrics] = useState(false)
  const [selectedArray, setSelectedArray] = useState([])
  const [currentRejectDeposit, setCurrentRejectDeposit] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  //Refs
  const inputRef = useRef<any>()
  const depositFilterBtn = useRef<any>()

  //Zustand Store
  const setGetData = useDepositStore((state: any) => state.setGetData)

  //Reject Deposit
  function rejectDeposit() {
    if (currentRejectDeposit !== '') {
      axios
        .post(
          `${SISPAY_API}/cash/depositrequest/reject`,
          {
            request: {
              id: currentRejectDeposit,
              //approvalRejectionRemark: remark,
              approvalRejectionRemark: inputRef.current.value,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          depositFilterBtn.current.click()
          if (response.data.code == '200') {
            alert('Deposit Rejected!')
            setGetData(true)
          } else {
            alert(response.data.message)
            setGetData(true)
          }
        })
        .catch(function (error) {})
    } else {
      alert('An error happened.')
    }

    setCurrentRejectDeposit('')
    inputRef.current.value = ''
  }

  useEffect(() => {
    let metrics = localStorage.getItem('depositMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  const keyMap = {
    id: 'ID',
    depositDT: 'Deposit DateTime',
    merchantName: 'Merchant',
    merchantCd: 'Merchant Code',
    customerCd: 'Customer Code',
    walletAccName: 'Wallet Account Name',
    walletCd: 'Wallet Account Code',
    amount: 'Amount',
    depositStatus: 'Deposit Status',
    callbackStatus: 'Callback Status',
    depositFee: 'Deposit Fee',
    adjustmentRemark: 'Adjustment Remark',
    approvalRejectionRemark: 'Approval/ Rejection Remark',
    submittedTxnId: 'Submitted Transaction ID',
    walletAccNo: 'Wallet Account No',
    agent: 'Agent',
    merchantTxnId: 'Merchant Transaction ID',
    srcWalletAcc: 'Source Wallet Acc',
    reference: 'Reference',
    matchStatus: 'Match Status',
    completedDT: 'Completed DateTime',
    successBy: 'Success By',
  }

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        enableColumnFilter: false,
        size: 50,
        header: ({table}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'ID',
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'depositDT',
        header: 'Deposit DateTime',
        accessorKey: 'depositDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'completedDT',
        header: 'Completed DateTime',
        accessorKey: 'completedDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'depositStatusUpdateDT',
        header: 'Status Updated DT',
        accessorKey: 'depositStatusUpdateDT',
        cell: (info) => FormatDate(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'merchantName',
        header: 'Merchant',
        accessorKey: 'merchantName',
        cell: (info) => info.getValue() + ' - ' + info.row.original.merchantCd,
        enableColumnFilter: false,
      },
      {
        id: 'customerCd',
        header: 'Customer Code',
        accessorKey: 'customerCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'walletAccName',
        header: 'Wallet Account Name',
        accessorKey: 'walletAccName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'walletCd',
        header: 'Wallet Code',
        accessorKey: 'walletCd',
        cell: (info: any) => info.getValue().toUpperCase(),
        enableColumnFilter: false,
      },
      {
        id: 'amount',
        header: 'Amount',
        accessorKey: 'amount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'depositStatus',
        header: 'Deposit Status',
        accessorKey: 'depositStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'callbackStatus',
        header: 'Callback Status',
        accessorKey: 'callbackStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'depositFee',
        header: 'Deposit Fee',
        accessorKey: 'depositFee',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        id: 'adjustmentRemark',
        header: 'Adjustment Remark',
        accessorKey: 'adjustmentRemark',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'approvalRejectionRemark',
        header: 'Approval/ Rejection Remark',
        accessorKey: 'approvalRejectionRemark',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'submittedTxnId',
        header: 'Submitted Transaction ID',
        accessorKey: 'submittedTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'walletAccNo',
        header: 'Wallet Account No',
        accessorKey: 'walletAccNo',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'agent',
        header: 'Agent',
        accessorKey: 'agent',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'merchantTxnId',
        header: 'Merchant Transaction ID',
        accessorKey: 'merchantTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'srcWalletAcc',
        header: 'Source Wallet Acc',
        accessorKey: 'srcWalletAcc',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'reference',
        header: 'Reference',
        accessorKey: 'reference',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'matchStatus',
        header: 'Match Status',
        accessorKey: 'matchStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'successBy',
        header: 'Success By',
        accessorKey: 'successBy',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        size: 100,
        cell: (info: any) => {
          let rowData: any = info.row.original

          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <a
                className='btn btn-icon btn-bg-danger btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                href='#manualMatchingModal'
                role='button'
                onClick={() => {
                  setDepositData(rowData)
                }}
                style={{
                  opacity:
                    rowData.depositStatus === 'PENDING' ||
                    rowData.depositStatus === 'REJECTED' ||
                    rowData.depositStatus === 'FAILED' ||
                    rowData.depositStatus === 'PENDING-RECHECK'
                      ? '1'
                      : '0',
                  visibility:
                    rowData.depositStatus === 'PENDING' ||
                    rowData.depositStatus === 'REJECTED' ||
                    rowData.depositStatus === 'FAILED' ||
                    rowData.depositStatus === 'PENDING-RECHECK'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={popover('Manual Matching')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <i className='bi bi-lightning-charge-fill'></i>
                  </div>
                </OverlayTrigger>{' '}
              </a>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_rejectDeposit'
                onClick={() => {
                  setCurrentRejectDeposit(rowData.id)
                }}
                style={{
                  opacity:
                    rowData.depositStatus === 'PENDING' ||
                    rowData.depositStatus === 'FAILED' ||
                    rowData.depositStatus === 'PENDING-RECHECK'
                      ? '1'
                      : '0',
                  visibility:
                    rowData.depositStatus === 'PENDING' ||
                    rowData.depositStatus === 'FAILED' ||
                    rowData.depositStatus === 'PENDING-RECHECK'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={popover('Reject Deposit')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <Link
                to={`/deposit/${rowData.id}`}
                state={rowData}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => setGetData(true)}
              >
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={popover('Check Details')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen038.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </Link>
            </div>
          )
        },
        enableColumnFilter: false,
      },
    ],
    []
  )

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='d-flex'>
          <div className='fv-row  me-5'>
            <button
              className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
              disabled={formData.length > 0 && !downloadLoading ? false : true}
              onClick={() => {
                Deposit_Export_CSV(filterValues, setDownloadLoading)
              }}
            >
              {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
            </button>
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_resubmit_callback'
            disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            BULK RESUBMIT CALLBACK
          </button>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deposit_bulk_approval'
            disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            BULK APPROVAL
          </button>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deposit_bulk_reject'
            disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            BULK REJECT
          </button>
          <Link
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            to={`/deposit/manual-deposit-creation`}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            MANUAL DEPOSIT CREATION
          </Link>
          {/* <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-dark me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_match_sms_nagad'
            // disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            Match SMS (NAGAD)
          </button>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-dark me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_match_sms_bkash'
            // disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            Match SMS (BKASH)
          </button> */}
        </div>
        <button
          type='button'
          className='btn btn-sm btn-flex fw-bold rotate'
          onClick={(e) => {
            setShowMetrics(!showMetrics)
            e.currentTarget.classList.toggle('active')
          }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr004.svg'
            className='svg-icon-6 svg-icon-muted me-1 rotate-180'
          />{' '}
          Select Metrics
        </button>
      </div>

      <ResubmitCallback
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        depositFilterBtn={depositFilterBtn}
      />
      <DepositManagement_BulkApproval
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        depositFilterBtn={depositFilterBtn}
      />
      <Filter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalCount={setTotalCount}
        setFormData={setFormData}
        formData={formData}
        setSelectedArray={setSelectedArray}
        depositFilterBtn={depositFilterBtn}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
        firstLoad={firstLoad}
        pagination={pagination}
        setFirstLoad={setFirstLoad}
        setRowSelection={setRowSelection}
      />
      <MatchSMSModal_Nagad />
      <MatchSMSModal_Bkash />
      <TableTemplateCSS>
        <TableTemplate
          data={formData}
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnPinning={columnPinning}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          showMetrics={showMetrics}
          manualPagination={true}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={totalCount}
        />
      </TableTemplateCSS>
      <DepositRejectRequestModal
        currentRejectDeposit={currentRejectDeposit}
        setCurrentRejectDeposit={setCurrentRejectDeposit}
        depositFilterBtn={depositFilterBtn}
        setGetData={setGetData}
      />
      <ManualMatching depositData={depositData} depositFilterBtn={depositFilterBtn} />
      <Deposit_Bulk_Reject_Modal
        selectedArray={selectedArray}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      {/* <div className='modal fade' tabIndex={-1} id='kt_modal_rejectDeposit'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Reject Deposit ID: {currentRejectDeposit}</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setCurrentRejectDeposit('')
                  inputRef.current.value = ''
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <h5>Remark:</h5>
              <textarea className='w-100 h-200px' ref={inputRef}></textarea>
            </div>
            <div className='modal-footer'>
              <button
                type='submit'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  rejectDeposit()
                }}
              >
                Reject Deposit
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default DepositManagementPage
