import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {filterArrayByMultipleKeys} from '../../components/Filter'
import {searching} from '../../components/Searching'
import {Overlay} from '../../components/Overlay'
import {useAuth} from '../../auth/core/Auth'
import {useCustCodeBlackListStore} from './CustCodeBlackListStore'
import {sortByDate} from '../../components/SortByDate'
import {sortByName} from '../../components/SortByName'
import getMerchant from '../../components/Get-Data-Function/getMerchant'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'

export function CustCodeBlackListFilter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Zustand Store
  const filterOptions = useCustCodeBlackListStore((state: any) => state.filterOptions)
  const getData = useCustCodeBlackListStore((state: any) => state.getData)
  const setFilterOptions = useCustCodeBlackListStore((state: any) => state.setFilterOptions)
  const setGetData = useCustCodeBlackListStore((state: any) => state.setGetData)

  //Props from parent
  let setFormData = props.setFormData

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState([])

  const selectedMerchantCd = useRef<any>(null)

  useEffect(() => {
    getMerchant(SISPAY_API, '/merchant/list', 'merchant', setMerchantCdList, session)
  }, [])

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    status: filterOptions.status ? String(filterOptions.status) : 'ACTIVE',
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  //Search function: START
  const keys = ['customerCd']

  //Search function: END

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)

      let search = values.search.trim()
      let status = values.status.trim()
      let merchantCd = values.merchantCd.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/blacklist/suspectedcustomer/list`,
          {
            request: {
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let result = response.data.result.depositRequestList

          let filteredFormData = filterArrayByMultipleKeys(
            result,
            ['status', 'merchantCd'],
            [status, merchantCd]
          )

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }

          setFormData(sortByDate(filteredFormData, 'createDate'))

          setLoading(false)
          setDisabled(false)
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return
    setDisabled(true)
    setLoading(true)

    let search = formik.values.search.trim()
    let status = formik.values.status.trim()

    axios
      .post(
        `${SISPAY_API}/blacklist/suspectedcustomer/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result.depositRequestList

        let filteredFormData = filterArrayByMultipleKeys(result, ['status'], [status])

        if (search !== '') {
          filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
        }

        setFormData(sortByDate(filteredFormData, 'createDate'))
        setGetData(false)
        setLoading(false)
        setDisabled(false)
      })
      .catch(function (error) {})
  }, [getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              <div className='fv-row mb-5'>
                <div className='row'>
                  <div className='col'>
                    {' '}
                    <div className='d-flex'>
                      <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                      <span>{Overlay('Search by Customer Code')}</span>
                    </div>
                    <input
                      placeholder='Enter your search'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('search')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.search && formik.errors.search,
                        },
                        {
                          'is-valid': formik.touched.search && !formik.errors.search,
                        }
                      )}
                    />
                    {formik.touched.search && formik.errors.search && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.search}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col'>
                    <div className='fv-row mb-5' id='merchantCd'>
                      <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
                      <Select
                        ref={selectedMerchantCd}
                        className='basic-single'
                        classNamePrefix='select'
                        options={merchantCdList}
                        styles={customStyles}
                        isClearable={true}
                        name='merchantCd'
                        value={
                          formik.values.merchantCd
                            ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                            : null
                        }
                        onChange={(e: any) =>
                          e !== null
                            ? formik.setFieldValue('merchantCd', e.value)
                            : formik.setFieldValue('merchantCd', '')
                        }
                      />
                      {formik.touched.merchantCd && formik.errors.merchantCd && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.merchantCd}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Status */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Status</label>
                      <select
                        {...formik.getFieldProps('status')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value='ACTIVE'>ACTIVE</option>
                        <option value=''>ALL</option>
                        <option value='INACTIVE'>INACTIVE</option>
                        <option value='SUSPENDED'>SUSPENDED</option>
                      </select>
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        formik.resetForm({
                          values: {
                            search: '',
                            merchantCd: '',
                            status: 'ACTIVE',
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
