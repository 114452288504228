import React, {useEffect, useState} from 'react'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import {useCustCodeBlackListStore} from './components/CustCodeBlackListStore'
import IndeterminateCheckbox from '../components/Table/IndeterminateCheckbox'
import {TableTemplateCSS} from '../components/Table/TableCSS'
import TableTemplate from '../components/Table/TableTemplate'
import {Link} from 'react-router-dom'
import {CustCodeBlackListFilter} from './components/CustCodeBlackListFilter'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import popover from '../components/Popover'
import {KTSVG} from '../../../_metronic/helpers'
import CustCodeBlackListCreateModal from './components/CustCodeBlackListCreateModal'

export default function CustCodeBlackListPage() {
  //Table Related
  const [showMetrics, setShowMetrics] = useState(false)
  const [selectedArray, setSelectedArray] = useState([])
  const [rowSelection, setRowSelection] = React.useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select'],
    right: ['action'],
  })
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => row)

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])
  const [firstLoad, setFirstLoad] = useState(true)
  //Table Related

  //Zustand Store
  const filterOptions = useCustCodeBlackListStore((state: any) => state.filterOptions)
  const getData = useCustCodeBlackListStore((state: any) => state.getData)
  const setFilterOptions = useCustCodeBlackListStore((state: any) => state.setFilterOptions)
  const setGetData = useCustCodeBlackListStore((state: any) => state.setGetData)

  //States
  const [selectedRowData, setSelectedRowData] = useState<any>([
    {
      walletAccName: '',
      merchantTimestamp: '',
      depositStatusUpdateDT: '',
      merchantTxnId: '',
      submittedTxnId: '',
      depositFee: '',
      merchantName: '',
      reference: '',
      approvalRejectionRemark: '',
      matchStatus: '',
      srcWalletAcc: '',
      depositDT: '',
      depositProcessId: '',
      id: '',
      customerCd: '',
      merchantCd: '',
      successBy: '',
      amount: '',
      walletAccNo: '',
      adjustmentRemark: '',
      completedDT: '',
      agentName: '',
      mobileNo: '',
      walletCd: '',
      callbackStatus: '',
      matchedTransTxnId: '',
      depositStatus: '',
    },
  ])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [formData, setFormData] = useState<any>([])

  console.log(formData)

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.3)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    margin: 'auto',
  }

  const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        size: 50,
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'ID',
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Customer Code',
        accessorKey: 'customerCd',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Merchant Code',
        accessorKey: 'merchantCd',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Create DateTime',
        accessorKey: 'createDate',
        cell: (info: any) => {
          return info.getValue().replace('T', ' ').replace(/\.\d+/, '')
        },
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Update DateTime',
        accessorKey: 'updateDate',
        cell: (info: any) => {
          if (info.getValue() === undefined) {
            return ''
          }
          return info.getValue().replace('T', ' ').replace(/\.\d+/, '')
        },
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: (info: any) => {
          return (
            <Link
              to={`/customer-code-blacklist/edit/${info.row.original.customerCd}`}
              state={info.row.original}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                setGetData(true)
              }}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('Edit')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
        enableColumnFilter: false,
      },
    ],
    []
  )

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0'>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#custCodeBlackListCreateModal'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-plus me-1'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            Add Customer Code Blacklist
          </button>{' '}
        </div>
      </div>{' '}
      <CustCodeBlackListCreateModal loading={loading} setLoading={setLoading} />
      <CustCodeBlackListFilter setFormData={setFormData} />
      <TableTemplateCSS>
        <TableTemplate
          data={formData}
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnPinning={columnPinning}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          showMetrics={showMetrics}
          manualPagination={false}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={totalCount}
        />
      </TableTemplateCSS>
    </div>
  )
}
