import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useAuth} from '../../auth'
import {ErrorHandling} from '../../components/ErrorHandling'
import {CustPendingDepExportExcel} from './components/Cust-Pending-Deposit-Export-Excel'
import TableTemplate from '../../components/Table/TableTemplate'
import IndeterminateCheckbox from '../../components/Table/IndeterminateCheckbox'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import FormatNumber from '../../components/FormatNumber'
import {TableTemplateCSS} from '../../components/Table/TableCSS'
import CustomerPendingBulkReject from './components/Cust-Pending-Bulk-Reject-Modal'
import {useCustPendingDepStore} from './components/Cust-Pending-Dpeosit-Store'

export default function CustPendingDepDetails_Modal(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  let selectedRow = props.selectedRow
  let setSelectedRow = props.setSelectedRow

  //Table Related
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [],
    right: [],
  })
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  const [totalCount, setTotalCount] = useState()
  const [showMetrics, setShowMetrics] = useState(false)
  //Table Related

  //Zustand Store
  const filterOptions = useCustPendingDepStore((state: any) => state.filterOptions)
  const getData = useCustPendingDepStore((state: any) => state.getData)
  const setFilterOptions = useCustPendingDepStore((state: any) => state.setFilterOptions)
  const setGetData = useCustPendingDepStore((state: any) => state.setGetData)

  //States
  const [selectedRowData, setSelectedRowData] = useState<any>([
    {
      walletAccName: '',
      merchantTimestamp: '',
      depositStatusUpdateDT: '',
      merchantTxnId: '',
      submittedTxnId: '',
      depositFee: '',
      merchantName: '',
      reference: '',
      approvalRejectionRemark: '',
      matchStatus: '',
      srcWalletAcc: '',
      depositDT: '',
      depositProcessId: '',
      id: '',
      customerCd: '',
      merchantCd: '',
      successBy: '',
      amount: '',
      walletAccNo: '',
      adjustmentRemark: '',
      completedDT: '',
      agentName: '',
      mobileNo: '',
      walletCd: '',
      callbackStatus: '',
      matchedTransTxnId: '',
      depositStatus: '',
    },
  ])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const bulkRejectModalRemark = useRef<any>(null)

  useEffect(() => {
    if (selectedRow == null) return

    setLoading(true)

    axios
      .post(`${SISPAY_API}/cash/listpendingdeposit/bycustomercd`, {
        request: {
          session: session,
          range: filterOptions.range, // ALL or TODAY
          timezone: filterOptions.timezone, // OPTIONAL : default GMT+6
          customerCd: selectedRow.customerCd,
        },
      })
      .then(function (response) {
        if (response.data.code == 200) {
          setSelectedRowData(response.data.result.depositRequestList)
          setLoading(false)
        } else {
          ErrorHandling(response, logout)
          setLoading(false)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }, [selectedRow, refresh])

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.3)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    margin: 'auto',
  }

  const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        size: 50,
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'ID',
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit DateTime',
        accessorKey: 'depositDT',
        cell: (info: any) => {
          return info.getValue().replace('T', ' ').replace(/\.\d+/, '')
        },
        enableColumnFilter: false,
      },
      {
        header: 'Completed DateTime',
        accessorKey: 'completedDT',
        cell: (info: any) => {
          return info.getValue().replace('T', ' ').replace(/\.\d+/, '')
        },
        enableColumnFilter: false,
      },
      {
        header: 'Status Updated DT',
        accessorKey: 'depositStatusUpdateDT',
        cell: (info: any) => {
          return info.getValue().replace('T', ' ').replace(/\.\d+/, '')
        },
        enableColumnFilter: false,
      },
      {
        header: 'Matched TxnId',
        accessorKey: 'matchedTransTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Submitted Transaction ID',
        accessorKey: 'submittedTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Merchant',
        accessorKey: 'customerCd',
        cell: (info: any) => {
          return info.getValue('merchantName') + ' - ' + info.getValue('merchantCd')
        },
        enableColumnFilter: false,
      },
      {
        header: 'Customer Code',
        accessorKey: 'customerCd',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account Name',
        accessorKey: 'walletAccName',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account Code',
        accessorKey: 'walletCd',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit Status',
        accessorKey: 'depositStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Callback Status',
        accessorKey: 'callbackStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit Fee',
        accessorKey: 'depositFee',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Adjustment Remark',
        accessorKey: 'adjustmentRemark',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Approval/ Rejection Remark',
        accessorKey: 'approvalRejectionRemark',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account No',
        accessorKey: 'walletAccNo',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Merchant Transaction ID',
        accessorKey: 'merchantTxnId',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Source Wallet Acc',
        accessorKey: 'srcWalletAcc',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Reference',
        accessorKey: 'reference',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Match Status',
        accessorKey: 'matchStatus',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Success By',
        accessorKey: 'successBy',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
    ],
    [selectedRowData]
  )

  return (
    <div>
      <div
        className='modal fade'
        id='custPendingDepDetailsModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                {selectedRow.customerCd}{' '}
                <span className='badge badge-danger ms-2'>
                  Pending Deposit Count:
                  {selectedRow.requestCount}
                </span>
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setSelectedRowData([])
                  setRowSelection({})
                  setSelectedRow({
                    requestCount: '',
                    customerCd: '',
                  })
                }}
              ></button>
            </div>
            <div className='modal-body'>
              {/* <div>
                <style>{spinKeyframes}</style>
                <div style={spinnerStyle} hidden={!loading ? true : false}></div>
              </div> */}
              <TableTemplateCSS>
                <TableTemplate
                  data={selectedRowData}
                  columns={columns}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  columnPinning={columnPinning}
                  showMetrics={showMetrics}
                  manualPagination={false}
                  pagination={pagination}
                  setPagination={setPagination}
                  totalCount={totalCount}
                />
              </TableTemplateCSS>
            </div>
            <div className='modal-footer d-flex flex-column'>
              <div className='w-100 d-flex flex-column justify-content-start align-items-start'>
                <h5 className=''>Remark:</h5>
                <input
                  type='text'
                  ref={bulkRejectModalRemark}
                  className='form-control'
                  placeholder='Enter Remark'
                />
              </div>
              <div className='w-100 d-flex justify-content-between mt-3'>
                <div>
                  <button
                    className='btn btn-info me-5'
                    disabled={loading}
                    onClick={() =>
                      CustPendingDepExportExcel(selectedRowData, selectedRow.customerCd)
                    }
                  >
                    Download Report
                  </button>
                  <button
                    className='btn btn-danger'
                    disabled={loading || Object.keys(rowSelection).length === 0}
                    onClick={() =>
                      CustomerPendingBulkReject(
                        rowSelection,
                        selectedRowData,
                        refresh,
                        setRefresh,
                        setRowSelection,
                        bulkRejectModalRemark
                      )
                    }
                  >
                    Bulk Reject Deposit
                  </button>
                </div>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setSelectedRowData([])
                    setRowSelection({})
                    setSelectedRow({
                      requestCount: '',
                      customerCd: '',
                    })
                  }}
                >
                  Close
                </button>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
