import axios from 'axios'
import {useRef} from 'react'
import {useDepositStore} from '../Deposit-Page/components/Deposit-Store'

export default function Deposit_Bulk_Reject_Modal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let selectedArray: any = props.selectedArray
  let setSelectedArray: any = props.setSelectedArray
  let setRowSelection: any = props.setRowSelection
  let depositFilterBtn: any = props.depositFilterBtn

  const bulkRejectModalRemark = useRef<any>(null)

  //Zustand Store
  const setGetData = useDepositStore((state: any) => state.setGetData)

  function filterPotentialDeposit(selectedArray: any) {
    if (selectedArray.length > 0) {
      let filteredArray: any = selectedArray.filter((data: any) => {
        return (
          data.depositStatus === 'PENDING' ||
          data.depositStatus === 'PENDING-RECHECK' ||
          data.depositStatus === 'INCOMPLETE' ||
          data.depositStatus === 'FAILED'
        )
      })
      return filteredArray
    } else {
      return []
    }
  }

  function filterNonPotentialDeposit(selectedArray: any) {
    if (selectedArray.length > 0) {
      let filteredArray: any = selectedArray.filter((data: any) => {
        return data.depositStatus === 'REJECTED' || data.depositStatus === 'SUCCESSFUL'
      })
      return filteredArray
    } else {
      return []
    }
  }

  function bulkReject(selectedArray: any) {
    let data = selectedArray.map((row: any) => row.id)

    axios
      .post(`${SISPAY_API}/cash/depositrequest/bulkreject`, {
        request: {
          session: session,
          depositReqs: data,
          approvalRejectionRemark: bulkRejectModalRemark.current.value,
        },
      })
      .then(function (response) {
        if (response.data.code == 200) {
          alert(response.data.message)
          bulkRejectModalRemark.current.value = ''
          setRowSelection({})
          setGetData(true)
        } else {
          alert(response.data.message)
          bulkRejectModalRemark.current.value = ''
          setRowSelection({})
          setGetData(true)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_deposit_bulk_reject'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Bulk Reject
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body d-flex flex-column'>
            <h5 className='mb-3'>Deposit request that could be rejected:</h5>
            <div className='d-flex flex-wrap mb-5 '>
              {filterPotentialDeposit(selectedArray).length > 0
                ? filterPotentialDeposit(selectedArray).map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'green',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px',
                          }}
                        >
                          {' '}
                          {data.id} | {data.depositStatus}
                        </div>
                      </div>
                    )
                  })
                : 'No Deposit Record Selected.'}
            </div>
            <div className='separator border-5 mb-5'></div>
            <h5 className='mb-3'>Deposit request that could not be rejected:</h5>
            <div className='d-flex flex-wrap'>
              {filterNonPotentialDeposit(selectedArray).length > 0
                ? filterNonPotentialDeposit(selectedArray).map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'red',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px',
                          }}
                        >
                          {' '}
                          {data.id} | {data.depositStatus}
                        </div>
                      </div>
                    )
                  })
                : 'No Deposit Record Selected.'}
            </div>
            <div className='separator border-5 mb-5'></div>
            <h5 className='mb-3'>Remark:</h5>
            <input
              type='text'
              ref={bulkRejectModalRemark}
              className='form-control form-control-solid'
              placeholder='Enter Remark'
            />
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            {selectedArray.length > 0 && (
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  bulkReject(selectedArray)
                }}
              >
                Bulk Reject
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
