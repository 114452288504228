import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {searching} from '../../../components/Searching'
import {sortByName} from '../../../components/SortByName'
import {Overlay} from '../../../components/Overlay'
import {useAuth} from '../../../auth/core/Auth'
import {ErrorHandling} from '../../../components/ErrorHandling'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {filterPartialMatch} from '../../../components/FilterPartialMatch'
import {useWalletListStore} from './WalletList_Store'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)

  //Refs
  const selectedMerchantCd = useRef<any>(null)

  //Zustand Store
  const filterOptions = useWalletListStore((state: any) => state.filterOptions)
  const getData = useWalletListStore((state: any) => state.getData)
  const setFilterOptions = useWalletListStore((state: any) => state.setFilterOptions)
  const setGetData = useWalletListStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    status: filterOptions.status,
    walletCd: filterOptions.walletCd,
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  const keys = ['walletAccName', 'walletAccNo']

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let status = values.status.trim()
      let walletCd = values.walletCd.trim()
      let merchantName = values.merchantCd.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/wallet/list`,
          {
            request: {
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let filteredFormData = filterArrayByMultipleKeys(
              response.data.result.wallet,
              ['status', 'walletCd'],
              [status, walletCd]
            )

            filteredFormData = filterPartialMatch(
              filteredFormData,
              ['merchantName'],
              [merchantName]
            )
            console.log(search)

            if (search !== '') {
              filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
            }

            console.log(search)

            console.log(filteredFormData)

            console.log(sortByName(filteredFormData, 'walletAccName'))

            setFormData(sortByName(filteredFormData, 'walletAccName'))
            setDisabled(false)
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          // alert(error)
        })
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)
    let search = formik.values.search.trim()
    let status = formik.values.status.trim()
    let walletCd = formik.values.walletCd.trim()
    let merchantName = formik.values.merchantCd.trim()

    axios
      .post(
        `${SISPAY_API}/wallet/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.wallet,
            ['status', 'walletCd'],
            [status, walletCd]
          )

          filteredFormData = filterPartialMatch(filteredFormData, ['merchantName'], [merchantName])

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }

          setFormData(sortByName(filteredFormData, 'walletAccName'))
          setGetData(false)
          setDisabled(false)
          setLoading(false)
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        // alert(error)
      })
  }, [getData])

  function handleRest() {
    selectedMerchantCd.current.setValue('')
    setSelectedArray([])
    formik.resetForm({
      values: {
        search: '',
        status: 'ACTIVE',
        walletCd: '',
        merchantCd: '',
      },
    })
  }

  useEffect(() => {
    axios
      .post(
        `${SISPAY_API}/merchant/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.merchant
          // Extract merchantCd and form a new array of JSON objects
          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        // alert(error)
      })
  }, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      {/* begin::Form group Search */}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Wallet Account Name, Wallet Account No')}
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group User type */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='BKASH'>BKASH</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='ROCKET'>ROCKET</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Status */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Status</label>
                        <select
                          {...formik.getFieldProps('status')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='ACTIVE'>ACTIVE</option>
                          <option value='INACTIVE'>INACTIVE</option>
                          <option value='DE-ACTIVE'>DE-ACTIVE</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Merchant Code */}
                      <div
                        className='fv-row mb-5'
                        style={{
                          zIndex: 9999,
                        }}
                      >
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>
                        <Select
                          ref={selectedMerchantCd}
                          className='basic-single'
                          classNamePrefix='select'
                          options={merchantCdList}
                          styles={customStyles}
                          isClearable={true}
                          name='merchantCd'
                          value={
                            formik.values.merchantCd
                              ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                              : null
                          }
                          onChange={(e: any) =>
                            e !== null
                              ? formik.setFieldValue('merchantCd', e.value)
                              : formik.setFieldValue('merchantCd', '')
                          }
                        />
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={handleRest}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                      // onClick={() => {
                      //   document.getElementById('filterBtn')!.click()
                      // }}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
