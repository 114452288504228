import React, {Ref, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Filter} from './components/filter'
import {pushUniqueValue} from '../../components/PushUniqueValue'
import {checkValueExists} from '../../components/CheckValueExists'
import {tickAllCheckbox} from '../../components/TickAllCheckbox'
import {
  Column,
  useTable,
  useResizeColumns,
  useFlexLayout,
  useFilters,
  usePagination,
} from 'react-table'
import BulkActive from './components/bulkActive'
import BulkDeactive from './components/bulkDeactive'
import ActivateAll from './components/activateAll'
import DeactivateAll from './components/deactivateAll'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {ColumnFilter} from '../../components/ColumnFilter'
import {metricsArray} from '../../components/MetricsArray'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {WalletList_ExportPDF} from './components/WalletList_ExportPDF'
import FormatNumber from '../../components/FormatNumber'
import {useWalletListStore} from './components/WalletList_Store'
import {TableWithFirstSecondAndLastColStick} from './components/WalletListStyle'

const WalletListPage: React.FC = () => {
  const userType: string = localStorage.getItem('userType') || ''
  const userRole: string = localStorage.getItem('userRole') || ''
  const username: string = localStorage.getItem('username') || ''

  //States
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [downloadAction, setDownloadAction] = useState(false)
  const [walletMetrics, setWalletMetrics] = useState<any>(undefined)
  const [test, setTest] = useState(Boolean)

  //Zustand Store
  const setGetData = useWalletListStore((state: any) => state.setGetData)

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  useEffect(() => {
    let walletMetrics = localStorage.getItem('walletMetrics')
    if (walletMetrics) {
      setWalletMetrics(JSON.parse(walletMetrics))
    } else {
      setWalletMetrics([])
    }
  }, [formData, showMetrics])

  //Table::START
  interface Data {
    id: string
    walletAccName: string
    walletAccNo: string
    walletCd: string
    walletType: string
    status: string
    merchantName: string
    userType: string
    agentName: string
    startBal: string
    balDiff: string
    createdDate: string
    updatedBy: string
    deactivatedDT: string
    telcoName: string
    pinNumber: string
  }

  const keyMap = {
    id: 'ID',
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account No',
    walletCd: 'Wallet Code',
    walletType: 'Wallet Type',
    status: 'Status',
    merchantName: 'Merchant Name',
    startBal: 'Starting Balance',
    balDiff: 'Balance Different',
    agentName: 'Agent Name',
    createdDate: 'Created Date',
    deactivatedDT: 'Deactivated Date',
    walletBal: 'Wallet Balance',
    isAgentWallet: 'Is Agent Wallet',
    agentCd: 'Agent Code',
    prefAcc: 'Preferred Account',
    weightage: 'Weightage',
    maxDeposit: 'Max Deposit',
    maxWithdrawal: 'Max Withdrawal',
    minDeposit: 'Min Deposit',
    minWithdrawal: 'Min Withdrawal',
    dailyDepositLmt: 'Daily Deposit Limit',
    dailyWithdrawalLmt: 'Daily Withdrawal Limit',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'selection',
        Header: () => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                title={'Select All ' + formData.length + ' Wallets'}
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id='checkAll'
                onChange={() => {
                  tickAllCheckbox(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={selectedArray.length > 0 && selectedArray.length === formData.length}
              />
            </div>
          )
        },
        width: 70,
        disableFilters: true,
        accessor: (formData: any) => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id={'checkbox_' + formData.walletAccNo}
                onChange={() => {
                  pushUniqueValue(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={checkValueExists(selectedArray, formData.id)}
              />
            </div>
          )
        },
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
        disableFilters: true,
      },
      {
        Header: 'Wallet Type',
        accessor: (row: any) => {
          if (row.walletType == 'DEPOSIT') {
            return 'Deposit'
          } else if (row.walletType == 'WITHDRAWAL') {
            return 'Withdrawal'
          } else {
            return 'Deposit & Withdrawal'
          }
        },
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
      },
      {
        Header: 'Merchant Name',
        accessor: 'merchantName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Starting Balance',
        accessor: (row: any) => {
          return FormatNumber(row.startBal)
        },
        disableFilters: true,
      },
      {
        Header: 'Balance Different',
        accessor: (row: any) => {
          return FormatNumber(row.balDiff)
        },
        disableFilters: true,
      },
      {
        Header: 'Agent Name',
        accessor: 'agentName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        disableFilters: true,
      },
      {
        Header: 'Created Date',
        accessor: (row) => {
          return row.createdDate.replace('T', ' ').split('.')[0]
        },
        disableFilters: true,
      },
      {
        Header: 'Deactivated Date',
        accessor: (row) => {
          return row.status == 'DE-ACTIVE' ? row.deactivatedDT.replace('T', ' ').split('.')[0] : ''
        },
        disableFilters: true,
      },
      {
        Header: 'Action',
        disableFilters: true,
        accessor: (row: any) => {
          return (
            <Link
              to={`/wallet/list/edit/${row.walletAccNo}`}
              state={row}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setGetData(true)}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('Edit')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
        width: 100,
      },
    ],
    [test, selectedArray]
  )

  const columns_itadmin = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'selection',
        Header: () => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                title={'Select All ' + formData.length + ' Wallets'}
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id='checkAll'
                onChange={() => {
                  tickAllCheckbox(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={selectedArray.length > 0 && selectedArray.length === formData.length}
              />
            </div>
          )
        },
        width: 70,
        disableFilters: true,
        accessor: (formData: any) => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id={'checkbox_' + formData.walletAccNo}
                onChange={() => {
                  pushUniqueValue(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={checkValueExists(selectedArray, formData.id)}
              />
            </div>
          )
        },
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
        Filter: ColumnFilter,
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
        disableFilters: true,
      },
      {
        Header: 'Telco Name',
        accessor: 'telcoName',
        disableFilters: true,
      },
      {
        Header: 'Pin Number',
        accessor: 'pinNumber',
        disableFilters: true,
      },
      {
        Header: 'Wallet Type',
        accessor: (row: any) => {
          if (row.walletType == 'DEPOSIT') {
            return 'Deposit'
          } else if (row.walletType == 'WITHDRAWAL') {
            return 'Withdrawal'
          } else {
            return 'Deposit & Withdrawal'
          }
        },
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
      },
      {
        Header: 'Merchant Name',
        accessor: 'merchantName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Starting Balance',
        accessor: (row: any) => {
          return FormatNumber(row.startBal)
        },
        disableFilters: true,
      },
      {
        Header: 'Balance Different',
        accessor: (row: any) => {
          return FormatNumber(row.balDiff)
        },
        disableFilters: true,
      },
      {
        Header: 'Agent Name',
        accessor: 'agentName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        disableFilters: true,
      },
      {
        Header: 'Created Date',
        accessor: (row) => {
          return row.createdDate.replace('T', ' ').split('.')[0]
        },
        disableFilters: true,
      },
      {
        Header: 'Deactivated Date',
        accessor: (row) => {
          return row.status == 'DE-ACTIVE' ? row.deactivatedDT.replace('T', ' ').split('.')[0] : ''
        },
        disableFilters: true,
      },
      {
        Header: 'Action',
        disableFilters: true,
        accessor: (row: any) => {
          return (
            <Link
              to={`/wallet/list/edit/${row.walletAccNo}`}
              state={row}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setGetData(true)}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('Edit')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
        width: 100,
      },
    ],
    [test, selectedArray]
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 170,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: {pageIndex, pageSize},
    } = useTable(
      {
        columns,
        data,
        initialState: {hiddenColumns: walletMetrics ? walletMetrics : []},
        defaultColumn,
      },
      useFilters,
      usePagination,
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('walletMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200 sticky-header'
                    >
                      {headerGroup.headers.map((column: any) => (
                        <div {...column.getHeaderProps()} className='th'>
                          {column.render('Header')}
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...(column as any).getResizerProps()}
                            className={`resizer ${(column as any).isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setDownloadAction(!downloadAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {downloadAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    WalletList_ExportPDF(formData, getCurrentDateInput(), getCurrentDateInput())
                    setDownloadAction(!downloadAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
          {userType === 'Admin' && userRole === 'operation' ? (
            ''
          ) : (
            <Link
              to='/wallet/list/create'
              className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary ms-3 me-5'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-plus me-1'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add Wallet
            </Link>
          )}{' '}
          <div
            className='position-relative me-5'
            style={{
              width: '150px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-warning w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Action
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_active'
                  disabled={selectedArray.length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK ACTIVE
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_deactive'
                  disabled={selectedArray.length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK INACTIVE
                </button>{' '}
              </div>
            )}
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_active_all'
          >
            ACTIVE ALL
          </button>{' '}
          <div
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deactive_all'
          >
            IN-ACTIVE ALL
          </div>{' '}
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter setFormData={setFormData} setSelectedArray={setSelectedArray} />
      <TableWithFirstSecondAndLastColStick>
        <Table
          columns={username.includes('itadmin') ? columns_itadmin : columns}
          data={currentItems == undefined ? [] : currentItems}
        />
      </TableWithFirstSecondAndLastColStick>
      {/* Modal */}
      <BulkActive
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
      />
      <BulkDeactive
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
      />
      <ActivateAll setFormData={setFormData} />
      <DeactivateAll setFormData={setFormData} />
      {/* Modal */}
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default WalletListPage
