import axios from 'axios'
import {sortByName} from '../SortByName'

export default function getMerchant(
  SISPAY_API: string,
  url: string,
  location: string,
  setMerchantCdList: any,
  session: any
) {
  axios
    .post(
      `${SISPAY_API}${url}`,
      {
        request: {
          session: session,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response: any) {
      let result = response.data.result[`${location}`]
      if (response.data.code == '200') {
        let merchantCdList: any = Array.from(
          new Set(result.map((request: any) => request.merchantCd))
        )
          .filter((merchantCd) => merchantCd !== '')
          .map((request) => {
            return {value: request, label: request}
          })
        merchantCdList = sortByName(merchantCdList, 'value')
        setMerchantCdList(merchantCdList)
      }
    })
    .catch(function (error) {
      alert(error)
    })
}
